@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Abel&family=Advent+Pro:wght@600&family=DM+Serif+Display:ital@0;1&family=Montserrat:wght@100;200;300;400;500&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Alice&family=Eczar:wght@400;500;600;700;800&display=swap');


h1 {
  font-family: 'Eczar', sans-serif;
  font-size: 32px;
}

h2,
h3 {
  font-family: 'Alice', sans-serif;
  font-size: 18px;
}

p {
  font-family: 'Abel', sans-serif;
}

button {
  font-family: 'Montserrat', sans-serif;
}

.logo {
  font-family: 'Eczar', sans-serif;
}

.bgImg:nth-child(1) {
  background: url(./assets/desktop/card1.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
}

.bgImg:nth-child(2) {
  background: url(./assets/desktop/card2.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
}

.bgImg:nth-child(3) {
  background: url(./assets/desktop/card3.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
}

.bgImg:nth-child(4) {
  background: url(./assets/desktop/card4.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
}

.bgImg:nth-child(5) {
  background: url(./assets/desktop/card5.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
}

.bgImg:nth-child(6) {
  background: url(./assets/desktop/card6.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
}

.bgImg:nth-child(1) .overlay {
  background: rgba(12, 36, 99, 0.4);
}

.bgImg:nth-child(2) .overlay {
  background: rgba(70, 67, 2, 0.6);
}

.bgImg:nth-child(3) .overlay {
  background: rgba(65, 128, 32, 0.6);
}

.bgImg:nth-child(4) .overlay {
  background: rgba(12, 36, 99, 0.4);
}

.bgImg:nth-child(5) .overlay {
  background: rgba(70, 67, 2, 0.6);
}

.bgImg:nth-child(6) .overlay {
  background: rgba(65, 128, 32, 0.6);
}

@layer base {
  h1 {
    @apply text-3xl
  }

  h2 {
    @apply text-2xl text-slate-900
  }

  h3 {
    @apply text-xl text-darkBlue
  }

  p {
    @apply text-[16px]
  }
}